<template>
    <div v-if="!$store.state.loadingScreen && Object.keys(companyData).length"
         :style="isMobile()?{height: '75vh'}:{height: '98.5vh'}"
         style="overflow-y: auto; overflow-x: hidden;">
        <el-row style="display: flex; align-items: center; background-color: #17484C !important;">
            <el-col :span="8" class="component-title" style="cursor: pointer;" v-if="!isMobile()">
                <img :src="`${publicPath}img/sv_homepage.svg`" class="yooni-logo" width="125px"
                 style="height: 100%; padding: 0.5vh 0.5vw;">

            </el-col>
            <el-col :span="8" v-else>
                &nbsp;
            </el-col>
            <el-col :offset="14" :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-row class="header-row"></el-row>
        <el-row style="background: white;">
            <el-row style="height: 30vh;">
                <img :src="`${publicPath}img/company/cover/${companyData.cover_image}`" alt="no image"
                     style="width: 100%; height: 30vh"/>
                <div class="company-name">{{ companyData.name }}</div>
                <img :src="`${publicPath}img/company/logo/${companyData.logo}`" alt="no image"
                     class="company-logo"/>
                <div :style="isMobile() ? {bottom: '1vh', 'text-align': 'center', 'width': '100%'}:{top: '1vh', right: '5vw'}"
                     style="position: absolute;">
                    <img v-if="companyData.linkedin !== ''" class="social-media-img"
                         @click="openSocialMedia(companyData.linkedin)"
                         :src="`${publicPath}img/icons8-linkedin.svg`" alt="linkedin"/>
                    <img v-if="companyData.twitter !== ''" class="social-media-img"
                         @click="openSocialMedia(companyData.twitter)"
                         :src="`${publicPath}img/icons8-twitter.svg`" alt="twitter"/>
                    <img v-if="companyData.facebook !== ''" class="social-media-img"
                         @click="openSocialMedia(companyData.facebook)"
                         :src="`${publicPath}img/icons8-facebook.svg`" alt="facebook"/>
                </div>
            </el-row>
            <el-row v-if="!isMobile()" class="sub-header">
                <div style="width: 50%;" class="sub-header-1">
                    <div v-if="companyData.website !== ''" class="college-info"
                         @click="openSocialMedia(companyData.website)">
                        <img :src="`${publicPath}img/website-logo.svg`" style="margin-right: 5px;">
                        <a>Visit Website</a>
                    </div>
                    <div v-if="companyData.phone !== ''" class="college-info">
                        <img :src="`${publicPath}img/contact.svg`" style="margin-right: 5px;">
                        {{ companyData.phone }}
                    </div>
                    <div v-if="companyData.email !== ''" class="college-info">
                        <img :src="`${publicPath}img/email.svg`" style="margin-right: 5px;" alt="no">
                        <a :href="'mailto:' + companyData.email + '?subject=Query Email from Yooni'" target="_blank">
                            Send email</a>
                    </div>
                </div>
                <div style="width: 50%;" class="sub-header-2">
                    <div v-if="companyData.work_experience !== ''" class="extra-tabs" style="background: #17484C ;"
                         @click="openSocialMedia(companyData.work_experience)">
                        <div>Work Experience<i class="el-icon-arrow-right"></i></div>
                    </div>
                    <div v-if="companyData.internship !== ''" class="extra-tabs" style="background: #17484C ;"
                         @click="openSocialMedia(companyData.internship)">
                        <div>Internships<i class="el-icon-arrow-right"></i></div>
                    </div>
                    <div v-if="companyData.job !== ''" class="extra-tabs" style="background: #17484C ;" @click="openSocialMedia(companyData.job)">
                        <div>Search Jobs<i class="el-icon-arrow-right"></i></div>
                    </div>
                </div>
            </el-row>
            <el-row v-else class="sub-header" style="background: white;">
                <div v-if="companyData.website !== ''" class="college-info"
                     @click="openSocialMedia(companyData.website)">
                    <img :src="`${publicPath}img/website-logo.svg`" style="margin-right: 5px;" alt="no">
                    <a>Visit Website</a>
                </div>
                <div v-if="companyData.work_experience !== ''" class="extra-tabs"
                     @click="openSocialMedia(companyData.work_experience)">
                    <div>
                        Work Experience
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <div v-if="companyData.phone !== ''" class="college-info">
                    <img :src="`${publicPath}img/contact.svg`" style="margin-right: 5px;" alt="no">
                    {{ companyData.phone }}
                </div>
                <div v-if="companyData.internship !== ''" class="extra-tabs"
                     @click="openSocialMedia(companyData.internship)">
                    <div>Internships
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <div v-if="companyData.email !== ''" class="college-info">
                    <img :src="`${publicPath}img/email.svg`" style="margin-right: 5px;" alt="no">
                    <a :href="'mailto:' + companyData.email + '?subject=Query Email from Yooni'" target="_blank">
                        Send email</a>
                </div>
                <div v-if="companyData.job !== ''" class="extra-tabs" @click="openSocialMedia(companyData.job)">
                    <div>Search Jobs
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </el-row>

            <el-row v-if="isMobile() && companyData.related_courses.length" style="margin: 16px;">
                <el-row style=" background: white;">
                    <div class="section-heading section-heading-main">
                        Related Courses
                    </div>
                    <el-row style="margin: 0.5vh 0;" v-for="(course, index) in companyData.related_courses"
                            :key="index">
                        <el-col>
                            <div class="related-course" @click="openCoursePage(course.id)">
                                <img :src="`${publicPath}img/bullet-point-1.svg`" style="margin-right: 10px;" alt="-">
                                {{ getRelatedCourse1(course) }}
                            </div>
                        </el-col>
                    </el-row>
                </el-row>
            </el-row>
        </el-row>

        <el-row style="background:#377F7E;">
            <el-col :xl="14" :lg="14" :md="{span: 24}" :sm="24" :xs="24"
                    :style="!isMobile()? {padding: '0'}:{}">
                <el-row :gutter="!isMobile() ? 20:0" style="margin: 16px;"
                        v-for="(section, index) in companyData.sections"
                        :key="index" :style="section.styleObj">
                    <el-row>
                        <el-col class="section-heading section-heading-main" style="color: white; font-family: 'Poppins'!important;">
                            {{ section.title }}
                        </el-col>
                    </el-row>
                    <el-row style="display: flex; align-items: center; flex-wrap: wrap;">
                        <el-col v-for="(column, key) in section.columns" :key="key"
                                :xs="column.xs" :sm="column.sm"
                                :md="column.md" :lg="column.lg" :xl="column.xl"
                                v-if="(column.visible === 'web' && !isMobile()) || (column.visible === 'mobile' && isMobile()) || column.visible === ''"

                                :style="column.styleObj" style="margin-top: 1vh; margin-bottom: 1vh;">
                            <div class="section-text" style="color: white;" v-if="column.type === 'text'" v-html="column.data"></div>
                            <div v-if="column.type === 'video'">
                                <iframe style="border: none; max-height: 300px; min-height: 200px;" allowfullscreen
                                        :src="column.data"
                                        width="100%"></iframe>
                            </div>
                            <div v-if="column.type === 'image'">
                                <img :src="`${publicPath}/${column.data}`"
                                     :style="column.styleObj"
                                     @click="column.link ? openSocialMedia(column.link):false"
                                     style="border: none; max-width: 80%;" alt="-">
                            </div>
                            <div v-if="column.type === 'link'">
                                <el-button @click="openSocialMedia(column.data)" class="visit-page" type="text">Visit
                                    Page <img style="width: 20px;" :src="`${publicPath}img/external-link.svg`" alt=".">
                                </el-button>
                            </div>
                            <gallery v-if="column.type === 'gallery'" :images="column.data" :index="imageIndex"
                                     @close="imageIndex = null"></gallery>
                            <el-row v-if="column.type === 'gallery'">
                                <el-carousel height="300px" :interval="5000" :type="!isMobile() ? 'card': ''">
                                    <el-carousel-item v-for="(image, iIndex) in column.data"
                                                      :key="iIndex">
                                        <div @click="imageIndex = iIndex"
                                             style="background-size: cover; background-repeat: no-repeat;"
                                             :style="{ backgroundImage: `url(${publicPath}${image})`, height: '300px' }">
                                        </div>
                                    </el-carousel-item>
                                </el-carousel>
                            </el-row>

                        </el-col>
                    </el-row>

                </el-row>
            </el-col>
            <el-col :xl="{span: 8, offset: 2}" :lg="{span: 8, offset: 2}" :md="{span: 8, offset: 2}" :sm="24"
                    :xs="24" :style="!isMobile()? {padding: '0 16px'}:{}">
                <el-row v-if="!isMobile() && companyData.related_courses.length"
                        style="background: white; margin: 2vh 0; padding: 16px;">
                    <div class="section-heading section-heading-main">
                        Related Courses
                    </div>
                    <el-row style="margin: 0.5vh 0;" v-for="(course, index) in companyData.related_courses"
                            :key="index">
                        <el-col :span="22">
                            <div class="related-course" @click="openCoursePage(course.id)">
                                <img :src="`${publicPath}img/bullet-point-1.svg`" style="margin-right: 10px;" alt="-">
                                {{ getRelatedCourse1(course) }}
                            </div>
                        </el-col>
                    </el-row>
                </el-row>
                <el-row style="margin: 2vh 0;" :style="isMobile() ? {padding: '0 16px'}:{}">
                    <el-col :span="12" v-if="companyData.company_pillars.length" style="padding-right: 8px;">
                        <div class="company-pillar-fit">
                            <div class="section-heading section-heading-sub">
                                Company Values
                            </div>
                            <div class="company-pillars" v-for="(pillars, index) in companyData.company_pillars"
                                 :key="index">
                                {{ pillars }}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12" style="padding-left: 8px;" v-if="companyName !== 'musgrave'">
                        <div class="company-pillar-fit">
                            <div class="section-heading section-heading-sub">
                                Personality Fit
                            </div>
                            <div class="company-pillars">
                                <div class="dot" :style="{backgroundColor: colorPalette[0]}"></div>
                                <span class="personality"
                                      @click="$router.push('/personalities/')">{{ companyData.personality_1 }}</span>
                                <i class="el-icon-arrow-right"></i>
                            </div>
                            <div class="company-pillars">
                                <div class="dot" :style="{backgroundColor: colorPalette[1]}"></div>
                                <span @click="$router.push('/personalities/')"
                                      class="personality">{{ companyData.personality_2 }}</span>
                                <i class="el-icon-arrow-right"></i>
                            </div>
                            <div class="company-pillars">
                                <div class="dot" :style="{backgroundColor: colorPalette[2]}"></div>
                                <span @click="$router.push('/personalities/')"
                                      class="personality">{{ companyData.personality_3 }}</span>
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin: 2vh 0; padding: 16px; background: white;"
                        v-if="companyData.salaries.length !== 0">
                    <div class="section-heading section-heading-main">
                        Salaries at {{ companyData.name }}
                    </div>
                    <div>
                        <div class="barDiv">
                            <div class="barDiv1"></div>
                            <div class="barDiv2"></div>
                            <div class="barDiv3"></div>
                        </div>
                        <div class="barDiv" v-if="companyData.salaries">
                            <div class="barDivText1">
                                {{ companyData.salaries[0].value }}
                            </div>
                            <div class="barDivText2">
                                MEDIAN<br>
                                {{ companyData.salaries[1].value }}
                            </div>
                            <div class="barDivText3">
                                {{ companyData.salaries[companyData.salaries.length - 1].value }}
                            </div>
                        </div>
                    </div>
                    <el-row style="margin-top: 2vh;">
                        <div class="salary-text" v-for="(salary, index) in companyData.salaries " :key="index">
                            {{ salary.title }} - {{ salary.value }}
                        </div>
                    </el-row>
                </el-row>
                <el-row style="margin: 2vh 0; padding: 16px; background: white;" v-if="companyData.awards.length">
                    <div class="section-heading section-heading-main">
                        Company Awards
                    </div>
                    <el-row>
                        <div v-for="(award, index) in companyData.awards" class="company-pillars" :key="index">
                            <div class="dot" :style="{backgroundColor: colorPalette[index]}"></div>
                            <span class="awards" v-html="award"></span>
                        </div>
                    </el-row>
                </el-row>
                <!--                <el-row v-if="isMobile()">-->
                <!--                    <el-carousel height="300px" :interval="5000">-->
                <!--                        <el-carousel-item v-for="(image, imageIndex) in images"-->
                <!--                                          :key="imageIndex">-->
                <!--                            <div @click="index = imageIndex"-->
                <!--                                 style="background-size: cover; background-repeat: no-repeat;"-->
                <!--                                 :style="{ backgroundImage: `url(${publicPath}${image})`, height: '300px' }"></div>-->
                <!--                        </el-carousel-item>-->
                <!--                    </el-carousel>-->
                <!--                </el-row>-->
            </el-col>
        </el-row>

    </div>
</template>

<script>
import VueGallery from 'vue-gallery';
import ProfileAvatar from '../../main_components/ProfileAvatar';

export default {
    name: 'CompanyInfo',
    components: {ProfileAvatar, 'gallery': VueGallery},
    props: ['companyName'],
    data: function () {
        return {
            colorPalette: ['#CC0044', '#428B98', '#5C4C87', '#3D95CA', '#FDC300', '#B66289', "#ff0000", "#669900"],
            companyData: [],
            images: [],
            imageIndex: null,
        };
    },
    mounted() {
        let self = this;
        this.images.push(`${self.publicPath}img/company/misc/${self.companyName}-1.png`);
        this.images.push(`${self.publicPath}img/company/misc/${self.companyName}-2.png`);
        this.images.push(`${self.publicPath}img/company/misc/${self.companyName}-3.png`);
        this.images.push(`${self.publicPath}img/company/misc/${self.companyName}-4.png`);
        let url = `${self.getBaseUrl()}/industry/${self.companyName}/`;
        this.sendAnalyticsEvents('company_page', self.companyName);
        this.sendAnalytics();
        self.$store.state.loadingScreen = true;
        $.ajax({
            url: url,
            beforeSend: function (request) {
                if (self.getAuthToken() !== '')
                    request.setRequestHeader('Authorization',
                        'Token ' + self.getAuthToken());
            },
            type: 'GET',
            success: function (response) {
                self.companyData = response[0];
                self.$store.state.loadingScreen = false;
            },
            error: function (error) {
                console.log(error);
                self.$store.state.loadingScreen = false;
            },
        });
    },
    computed: {},
    methods: {
        openCoursePage: function (id) {
            let routeData = this.$router.resolve({name: 'Course', params: {courseId: id}});
            window.open(routeData.href, '_blank');
            // this.$router.push(`/course/${id}/`)
        },
        getRelatedCourse1: function (course) {
            if (course !== undefined)
                return course.code + ' - ' + course.title;
            return '';
        },
        openSocialMedia: function (link) {
            if (!link.includes('http:')) {
                link = 'https://' + link;
            }
            let win = window.open(link, '_blank');
            win.focus();
        },
    },
};
</script>

<style scoped>
.awards, .personality {
    margin-left: 10px;
    width: calc(100% - 10px)
}

.personality {
    cursor: pointer;
}

.personality:hover {
    text-decoration: underline;
}

.salary-text {
    font-weight: normal;
    color: #5C5C5C;
    text-align: left;
    margin: 1vh 0;
}

.dot {
    border-radius: 50%;
}

.company-pillars {
    font-weight: 400;
    text-align: left;
    color: #5C5C5C;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.company-pillar-fit {
    background: #DAE5ED;
    border-radius: 3px;
}

.related-course {
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #5C5C5C;
    text-align: left;
}

.related-course:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #5C5C5C;
}

.header-row {
    background: #3A3F5C;
    height: 5vh;
    width: 100%;
}

.company-name {
    position: absolute;
    font-family: Niramit, serif !important;
    font-style: normal;
    font-weight: bolder;
    text-align: center;
    color: #ffffff;
    padding: 16px;
    background: rgba(58, 49, 96, 0.3);
}

.extra-tabs {
    height: 4vh;
    background: #59A3CC;
    border-radius: 60px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

.extra-tabs div {
    text-align: center;
    width: 100%;
    margin-right: 0;
}

.extra-tabs:hover {
    cursor: pointer;
    text-decoration: underline;
}

.company-info {
    text-align: justify;
    color: rgba(19, 19, 54, 0.7);
    margin-top: 1.5vh;
}


.section-heading {
    font-family: Niramit, serif !important;
    font-style: normal;
    font-weight: normal;
    text-align: left;
    color: #3A3160;
}

.section-text {
    font-weight: 300;
    color: #333333;
    text-align: justify;
}

.social-media-img {
    margin-right: 10px;
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.barDiv {
    height: 30px;
    width: 80%;
    margin: 0 auto;
}

.barDivText1, .barDivText2, .barDivText3 {
    opacity: 0.7;
    font-weight: 700;
    font-size: 12px;
}

.barDiv1 {
    float: left;
    height: 100%;
    width: 20%;
    background: #ECAA77;
}

.barDiv2 {
    float: left;
    height: 100%;
    width: 50%;
    background: #4183A3;
}

.barDivText1 {
    float: left;
    width: 20%;
    text-align: center;
}

.barDivText2 {
    float: left;
    text-align: center;
    width: 50%;
}

.barDiv2:after {
    content: '';
    border-right: 2px white dashed;
    height: 30px;
    transform: translateX(-50%);
    display: block;
}

.barDiv3 {
    float: left;
    height: 100%;
    width: 30%;
    background: #4BA7A3;
}

.barDivText3 {
    float: left;
    width: 30%;
    text-align: center;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
}

.visit-page {
    display: flex;
    align-items: center;
    justify-content: left;
}
</style>
